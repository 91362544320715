<template>
  <v-snackbar
    :timeout="10000"
    :color="popupState.color"
    variant="flat"
    target="cursor"
    location="top"
    class="text-center ma-2"
    v-model="popupState.isActive"
  >
    {{ popupState.message }}
    <template #actions>
      <v-btn
        icon="mdi-close"
        color="white"
        size="small"
        variant="tonal"
        @click="clearPopup"
      />
    </template>
  </v-snackbar>
</template>

<script lang="ts" setup>
// import { TIMEOUT } from "~/constants/timeout";

const { popupState, clearPopup } = usePopup()
</script>
